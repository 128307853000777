import React, { useState, useEffect } from 'react';

const HeroSection = () => {
  const texts = [
    "Chartered Certified Accountants",
    "Focus on your business...",
    "We do the financials..."
  ];

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const [charIndex, setCharIndex] = useState(0);
  const typingSpeed = 100; // Speed of typing in ms
  const deletingSpeed = 50; // Speed of deleting in ms

  useEffect(() => {
    const typeEffect = setTimeout(() => {
      if (!isDeleting && charIndex < texts[currentTextIndex].length) {
        // Typing effect
        setDisplayedText((prev) => prev + texts[currentTextIndex][charIndex]);
        setCharIndex((prev) => prev + 1);
      } else if (isDeleting && charIndex > 0) {
        // Deleting effect
        setDisplayedText((prev) => prev.slice(0, -1));
        setCharIndex((prev) => prev - 1);
      } else if (!isDeleting && charIndex === texts[currentTextIndex].length) {
        // Wait before deleting
        setTimeout(() => setIsDeleting(true), 1000);
      } else if (isDeleting && charIndex === 0) {
        // Move to the next text
        setIsDeleting(false);
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
      }
    }, isDeleting ? deletingSpeed : typingSpeed);

    return () => clearTimeout(typeEffect);
  }, [charIndex, isDeleting, texts, currentTextIndex]);

  return (
    <section id="home" className="mb-50 overflow-hidden">
      <div className="hero-section"></div>
      <div className="relative overflow-hidden bg-cover bg-no-repeat bg-[50%] h-[750px] bg-[url('./images/landing.jpg')]">
        <div className="absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-fixed bg-[hsla(0,0%,0%,0.75)]">
          <div className="flex h-full items-center justify-center">
            <div className="px-6 text-center text-white md:px-12">
              {/* <h1 className="mt-6 mb-16 text-5xl font-bold tracking-tight md:text-6xl xl:text-7xl">
                DGM Consultants
              </h1> */}
              <h2 className="mt-6 mb-16 text-5xl font-bold tracking-tight md:text-6xl xl:text-7xl">
                <span>{displayedText}</span>
                <span className="blinking-cursor">|</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
