import namben from '../images/namben.jpeg';
import didier from '../images/didier.jpeg';
import dewen from '../images/dewen.jpg';


const people = [
  {
    name: 'DEWEN MOOTOOSAMY',
    role: 'Founder | CEO',
    denomination: 'FCCA',
    desc: 
      'Worked at Kemp Chatteris and Coopers & Lybrand. Involved in consultancy assignments in Rwanda, Tanzania and Burundi as a Chartered Certified Accountant and a registered Insolvency practitioner. Has more than 2 decades of private practice experience and has a wide understanding of businesses and has worked across a variety of sectors including banks and insurance companies. He was a partner of a firm in Rwanda, GN Partners and has also been a member of the Audit Panel of the Financial Reporting Council.',
    imageUrl: dewen,
  },
  {
    name: 'NAMBEN MOOTOOSAMY',
    role: 'SENIOR ACCOUNTANT',
    denomination: 'ACCA | BSc (Hons) Financial Economics',
    desc: 'Graduate from the University of Leicester, United Kingdom and a qualified ACCA Affiliate. He has had his training in the firm before 2011 and has been in full-time employment since 2012. He has been involved in the Tax Department, Auditing section, Project Appraisal exercises and Advisory.',
    imageUrl:namben,
  },
  {
    name: 'DIDIER HWL',
    role: 'SENIOR ACCOUNTANT',
    denomination: 'FCCA',
    desc: 'Fellow member of the Association of Chartered Certified Accountants. With over 15 years of experience in the accounting, auditing and taxation world.',
    imageUrl:didier,
  }
];

export default function Staff() {
  return (
    <div id="team" className="bg-gray-900 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl mb-12 text-center">MEET THE TEAM</h2>
        <ul role="list" className="grid gap-x-8 gap-y-12 sm:grid-cols-2 md:grid-cols-3">
          {people.map((person) => (
            <li key={person.name} className="group perspective">
              <div className="relative h-80 w-full [transform-style:preserve-3d] transition-transform duration-500 group-hover:[transform:rotateY(180deg)]">
                {/* Front of Card */}
                <div className="absolute inset-0 bg-white shadow-lg rounded-xl p-6 [backface-visibility:hidden] flex flex-col items-center justify-center">
                  <img className="h-40 w-40 rounded-full mb-4" src={person.imageUrl} alt={person.name} />
                  <h3 className="text-lg font-semibold text-gray-900">{person.name}</h3>
                  <h4 className="text-sm text-indigo-600 font-medium">{person.role}</h4>
                  <p className="text-sm text-gray-500 mt-1">{person.denomination}</p>
                </div>

                {/* Back of Card */}
                <div className="absolute inset-0 bg-indigo-100 shadow-lg rounded-xl p-6 [backface-visibility:hidden] [transform:rotateY(180deg)] flex items-center justify-center">
                  <p className="text-sm text-gray-700 text-center">{person.desc}</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
