export default function Footer() {
    return (
      <div id="footer" className="bg-gray-900 text-white py-8">
        <footer>
          <div id="footer-section" className="text-center">
            <div className="container mx-auto px-4">
              <div className="flex flex-col items-center">
                <p className="text-sm font-medium">
                  &copy; {new Date().getFullYear()} Created and Maintained by
                </p>
                <a
                  href="https://www.linkedin.com/in/kevicen/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-indigo-400 hover:underline mt-1"
                >
                  Kevissen
                </a>
              </div>
              <div className="mt-4">
                <ul className="flex justify-center space-x-4">
                  <li>
                    <a
                      href="https://www.linkedin.com/in/kevicen/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-400 hover:text-indigo-400"
                    >
                      LinkedIn
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://github.com/kevicen"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-gray-400 hover:text-indigo-400"
                    >
                      GitHub
                    </a>
                  </li>
                  <li>
                    <a
                      href="mailto:kevicen+dgm@gmail.com"
                      className="text-gray-400 hover:text-indigo-400"
                    >
                      Email
                    </a>
                  </li>
                </ul>
              </div>
              <div className="mt-4 text-sm text-gray-500">
                <p>All rights reserved. Powered by creativity and code.</p>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
  