import './index';
import Team from './components/Team';
import Portforlio from './components/Portfolio';
import Navibar from './components/navibar';
import AboutUs from './components/aboutus';
import HeroSection from './components/Hero';
import ContactUs from './components/contactus';
import Footer from './components/footer';


function App() {
  return (
    <div className="bg-gray-200 min-h-screen font-serif">
      <>
        <HeroSection/>
        <AboutUs />
        <Navibar/>
        <Portforlio/>
        <Team/>
        <ContactUs />
        <Footer />
      </>
    </div>
  );
}

export default App;
