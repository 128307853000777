import React from 'react';
import companyLogo from "../images/company_logo.png";

const ContactUs = () => {
  return (
    <div id="contact" className="bg-gray-200 pt-24 sm:pt-32">
      <div className="text-center mb-12">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          GET IN TOUCH
        </h2>
      </div>

      {/* Address & Form in a grid */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-12 grid grid-cols-1 md:grid-cols-3 gap-12">
        {/* Address Section */}
        <div className="bg-gray-900 text-white p-6 rounded-lg shadow-md flex flex-col items-start justify-start space-y-6">
          <div className="flex items-start space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M12 2.25c4.97 0 9 3.798 9 8.466 0 3.555-2.7 6.765-7.2 9.415a1.27 1.27 0 01-1.2 0C7.7 17.481 5 14.27 5 10.716 5 6.048 7.03 2.25 12 2.25z"
              />
            </svg>
            <div>
              <h3 className="text-lg font-medium">Address</h3>
              <p>
                <a
                  href="https://goo.gl/maps/s5eTFM8UXwPC3u4j9"
                  className="text-white hover:underline"
                >
                  Suite 703, Level 7, Richard House, <br />
                  Remy Ollier Street, Port Louis, Mauritius
                </a>
              </p>
            </div>
          </div>

          <div className="flex items-start space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5M3 18.75h18a1.5 1.5 0 001.5-1.5v-12a1.5 1.5 0 00-1.5-1.5H3A1.5 1.5 0 001.5 5.25v12A1.5 1.5 0 003 18.75z"
              />
            </svg>
            <div>
              <h3 className="text-lg font-medium">Phone</h3>
              <p>
                <a
                  href="tel:+2302178926"
                  className="text-white hover:underline"
                >
                  (+230) 217-8926
                </a>
              </p>
              <p>
                <a
                  href="tel:+23052548614"
                  className="text-white hover:underline"
                >
                  (+230) 5254-8614
                </a>
              </p>
            </div>
          </div>

          <div className="flex items-start space-x-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21.75 4.5l-9.9 9.9c-.292.293-.677.43-1.07.43H7.5v-3.28c0-.393.137-.778.43-1.07l9.9-9.9a2.25 2.25 0 013.18 0l1.5 1.5a2.25 2.25 0 010 3.18z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 9l6 6M12.75 21.75h8.25M3 21.75h6"
              />
            </svg>
            <div>
              <h3 className="text-lg font-medium">Email</h3>
              <p>
                <a
                  href="mailto:dgm.cons.mu@inet.mu"
                  className="text-white hover:underline"
                >
                  DGM.CONS@INTNET.MU
                </a>
              </p>
            </div>
          </div>

          <div className=" flex justify-center mt-6">
            <img
              src={companyLogo}
              alt="DGM Consultants"
              className="h-16 w-auto object-contain max-w-full"
            />
          </div>
        </div>

        {/* Contact Form Section */}
        <div className="bg-white p-8 rounded-lg shadow-lg md:col-span-2">
          <h3 className="text-2xl font-semibold text-gray-900 mb-4">
            Send Us a Message
          </h3>
          <form>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-900 focus:ring-gray-900 sm:text-sm"
                placeholder="Your Name"
                required
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-900 focus:ring-gray-900 sm:text-sm"
                placeholder="Your Email"
                required
              />
            </div>

            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-sm font-medium text-gray-700"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows="4"
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-900 focus:ring-gray-900 sm:text-sm"
                placeholder="Your Message"
                required
              ></textarea>
            </div>

            <button
              type="submit"
              className="w-full bg-gray-900 text-white py-2 px-4 rounded-md shadow hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
            >
              Send Message
            </button>
          </form>
        </div>
      </div>

      {/* Map Section */}
      <div className="mx-auto max-w-7xl px-6 lg:px-8 mt-12 mb-12">
        <div className="relative w-full h-[500px]">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3745.346554580014!2d57.50427001491829!3d-20.161291186474596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x217c51a632544111%3A0x1963d7b4fc32faa6!2sDGM%20Consultants%20LTD!5e0!3m2!1sen!2smu!4v1686140494996!5m2!1sen!2smu"
            title="Company Location"
            className="absolute inset-0 h-full w-full rounded-lg"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
