const abt = [
    {
      name: 'A leading firm of Chartered Accountants, Tax Consultants, Business advisors and Auditors.',
      desc: 
      'With a portfolio ranging from financial and business services to corporates entities and individuals in Mauritius as well as Rodrigues, supporting them to achieve growth and success. We are competitive, efficient and human-centered in our approach.',
      
    },

    {
      name: 'Business with a personal touch',
      desc: 
      'DGM Consultants is a trusted and reputable financial services provider specializing in comprehensive accounting and advisory solutions. With a team of highly skilled professionals and a commitment to excellence, we offer a wide range of services tailored to meet the unique needs of your businesses.',
  
    },

  ]
  
  export default function AboutUs() {
    return (
      <div id="about" className="bg-gray-900  py-16 sm:py-24">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">ABOUT US</h2>
        </div>
        <div className="mt-12 flex flex-col items-center space-y-8 sm:space-y-0 sm:flex-row sm:justify-center sm:gap-8 px-4 sm:px-0">
          {abt.map((item) => (
            <div
              key={item.name}
              className="w-full max-w-md bg-white rounded-xl shadow-lg p-8 space-y-4 transition-transform transform hover:scale-105 hover:shadow-2xl"
            >
              <h3 className="text-lg font-semibold leading-7 text-gray-900">{item.name}</h3>
              <p className="text-gray-600 text-base leading-6">{item.desc}</p>
            </div>
          ))}
        </div>
      </div>
    );
  }