import { useState } from 'react';
import { Menu, X } from 'lucide-react';
import companyLogo from "../images/company_logo.png";

export default function Navibar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div id="home">
      <nav className="fixed top-0 w-full bg-white py-3 shadow-xl z-50">
        <div className="container mx-auto flex items-center justify-between px-4">
          {/* Company Logo */}
          <a href="/#home" className="flex items-center">
            <img src={companyLogo} alt="Company Logo" className="h-8 w-auto mr-2" />
            {/* <span className="text-slate-900 font-extrabold text-xl tracking-wide">DGM</span> */}
          </a>

          {/* Hamburger Menu Icon */}
          <button 
            className="text-slate-900 focus:outline-none md:hidden" 
            onClick={toggleMenu}
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>

          {/* Desktop Menu */}
          <div className="hidden md:flex space-x-6">
            {[['HOME', '/#home'], ['ABOUT US', '/#about'], ['SERVICES', '/#service'], ['TEAM', '/#team'], ['CONTACT', '/#contact']].map(([title, url]) => (
              <a
                key={title}
                href={url}
                className="rounded-lg px-4 py-2 text-slate-900 font-semibold hover:bg-gray-200 hover:text-blue-700 transition-all ease-in-out duration-300"
              >
                {title}
              </a>
            ))}
          </div>
        </div>

        {/* Mobile Menu */}
        {isMenuOpen && (
          <div className="absolute top-0 left-0 w-full h-screen bg-white flex flex-col items-center justify-center space-y-6">
            {/* Close Button */}
            <button 
              className="absolute top-4 right-4 text-slate-900 focus:outline-none" 
              onClick={toggleMenu}
            >
              <X size={24} />
            </button>

            {/* Company Logo in Mobile Menu */}
            <a href="/#hero" className="flex items-center mb-8">
              <img src={companyLogo} alt="Company Logo" className="h-12 w-auto" />
            </a>

            {[['HOME', '/#home'], ['ABOUT US', '/#about'], ['SERVICES', '/#service'], ['TEAM', '/#team'], ['CONTACT', '/#contact']].map(([title, url]) => (
              <a
                key={title}
                href={url}
                onClick={() => setIsMenuOpen(false)}
                className="text-2xl text-slate-900 font-semibold hover:text-blue-700 transition-all ease-in-out duration-200"
              >
                {title}
              </a>
            ))}
          </div>
        )}
      </nav>
    </div>
  );
}
